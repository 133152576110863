<template>
  <div class="miserver-viewer" v-if="miserver !== null">
    <h1 class="title">
      {{ miserver.name }}
      <span class="ti-tag version">{{ miserver.version }}</span>
      <span class="ti-tag apiversion">{{ miserver.apiversion }}</span>
    </h1>
    <h4 class="sub-name">
      {{ miserver.endpoint }}
      <a target="endpoint" :href="miserver.endpoint"><i class="uil uil-external-link-alt" /></a>
    </h4>
    <grid>
      <grid-col size="8">
        <card title="Info">
          <TextProperty title="User" :value="miserver.username" />
          <TextProperty title="Created" :value="$date(miserver.createdAt).format('LLL')" />
          <TextProperty title="Enabled" :value="miserver.available ? 'Yes' : 'No'" />
          <TextProperty title="Checked" :value="$date(miserver.checkedAt).fromNow()" />
          <TextProperty
            title="Check Interval"
            :value="'every ' + duration(miserver.checkInterval * 1000)"
          />
          <TextProperty title="Token Valid Until" v-if="tokenValidity" :value="tokenValidity" />
          <hr class="spacing" />
          <div class="clearfix">
            <router-link
              v-if="isAdmin() || userCan('edit', 'miserver')"
              :to="{ name: 'miserverEdit', params: { miserver: miserver._id } }"
            >
              <base-button type="THEMED" title="Edit" iconType="EDIT" />
            </router-link>
            <base-button
              v-if="isAdmin() || userCan('delete', 'miserver')"
              v-on:click="deleteMiServer"
              type="THEMED_DELETE"
              isSmall
              iconType="TRASH"
              title="Delete"
            />
            <base-button
              v-if="isAdmin() || userCan('edit', 'miserver')"
              isSmall
              type="THEMED"
              v-on:click="toggleMiServer"
              :iconType="miserver.available ? 'CANCEL' : 'CHECK'"
              :title="miserver.available ? 'Disable' : 'Enable'"
            />
            <base-button
              v-if="isAdmin()"
              isSmall
              type="THEMED"
              v-on:click="scanMiServer"
              title="Scan"
              ><i class="uil uil-edit" />
            </base-button>
            <router-link
              v-if="isAdmin()"
              :to="
                genFilter({
                  system: { os: 'magicinfo' },
                  miserver: miserver._id,
                })
              "
            >
              <base-button type="THEMED" title="Show devices"
                ><i :class="'uil uil-shield-check'"
              /></base-button>
            </router-link>
          </div>
        </card>
      </grid-col>
    </grid>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';
import Filter from '@/filter';
import BaseComponent from '../Base.vue';
import TextProperty from '../Property/TextProperty.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'singlemiserver',
  extends: BaseComponent,
  components: {
    Card,
    TextProperty,
    Grid,
    GridCol,
    BaseButton,
  },
  props: ['miserverId'],
  watch: {
    miserverId(newVal) {
      this.getmiserver(newVal);
    },
  },
  data() {
    return {
      ajaxCompleted: false,
      miserver: null,
      tokenValidity: null,
    };
  },
  methods: {
    duration(ms) {
      return Utils.duration(ms);
    },
    userCan(...args) {
      return Utils.userCan(...args);
    },
    handleUserEdit(id) {
      this.editUser = id;
    },
    genFilter(options) {
      return `/devices/approved#${Filter.serializeFilter(Filter.generate(options))}`;
    },
    async getmiserver(id) {
      const response = await Utils.fetch(`/api/v1/miservers/${id}`, {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.ajaxCompleted = true;
        this.miserver = response.miserver;

        if (this.miserver.token) {
          const jwtObj = JSON.parse(Buffer.from(this.miserver.token.split('.')[1], 'base64'));
          // eslint-disable-next-line no-undef
          this.tokenValidity = this.$date(jwtObj.exp * 1000).toString();
        }
      }
    },
    async deleteMiServer() {
      if (
        // eslint-disable-next-line no-restricted-globals
        confirm(
          'Do you really want to delete this MagicINFO server connection and all the connected devices?',
        )
      ) {
        const response = await Utils.fetch(
          `/api/v1/miservers/${this.miserverId}`,
          {
            method: 'DELETE',
          },
          this,
        ).then((res) => res.json());
        if (response.success) {
          this.$noty.success(
            `Successfully deleted the MagicINFO server connection '${this.miserver.name}'`,
          );
          this.$emit('deleted');
          this.$router.push('/miservers');
        } else {
          this.$noty.warning(response.message);
        }
      }
    },
    async toggleMiServer() {
      const response = await Utils.fetch(
        `/api/v1/miservers/toggle/${this.miserverId}`,
        {},
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.miserver = response.miserver;
        this.$noty.success(`Toggled the MagicINFO server '${this.miserver.name}'`);
        this.$root.$emit('reloadTable');
      } else {
        this.$noty.warning(response.message);
      }
    },
    async scanMiServer() {
      const response = await Utils.fetch(
        `/api/v1/miservers/scan/${this.miserverId}`,
        {},
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.miserver = response.miserver;
        this.$noty.success(`Started a job to scan the MagicINFO server '${this.miserver.name}'`);
      } else {
        this.$noty.warning(response.message);
      }
    },
  },
  mounted() {
    this.getmiserver(this.miserverId);
  },
};
</script>

<style scoped lang="scss">
h1.title span.ti-tag {
  background-color: #f8b36b;
  color: #1e2a31;
  border-radius: 2px;
  display: inline-block;
  padding: 3px 5px;
  margin: 2px;
  font-size: 11px;
  top: -5px;
  position: relative;

  &:first-child {
    margin-left: 10px;
  }
}

h1.title span.ti-tag.apiversion {
  background-color: #915e00;
}

h1.title span.ti-tag.apiversion:before {
  content: 'api:';
}

.actions a {
  margin-left: 5px;
}

.spacing {
  padding-bottom: 15px;
}

.button {
  display: block;
  float: left;
  margin: 2px;
}

.themed-scan {
  background: rgba(255, 255, 255, 0.1) !important;
  color: rgba(255, 255, 255, 0.6);

  &:hover {
    background: rgb(35, 55, 128) !important;
    color: rgba(255, 255, 255, 1);
  }
}
</style>
